.franchise-dashboard-container {
    margin: 20px 24px 44px 44px;
    animation: fadeInFromDownBookings1 0.3s ease-out !important;
}
  
.franchise-dashboard-toggle {
    padding-right: 4px;
}

.franchise-dashboard-content::-webkit-scrollbar {
    display: none;
}

.ui.dropdown.franchise-dropdown>.dropdown.icon {
    margin-left: 6px !important;
  }
  
@media screen and (max-width: 960px) {
    .franchise-dashboard-container {
        margin: 20px 25px 12px 24px;
    }
  
    .franchise-dashboard-toggle {
      padding-right: 4px;
    }  
}
  
@media screen and (max-width: 768px) {
    .franchise-dashboard-container {
        margin: 20px 8px 44px 8px;
        padding-bottom: 100px;
    }
  
    .franchise-dashboard-toggle {
        padding-right: 18px;
        margin-top: 24px;
    }
}