.create-qr-container {
    position: inherit;
    margin: 23px 14px 12px 44px;
    padding: 20px;
    background-color: white;
    max-height: calc(100vh - 220px); 
    min-height: 290px;
    border-radius: 20px;
    box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
    transition-property: box-shadow, transform;
    color: #333;
    overflow-y: scroll;
  }
  
  .create-qr-container::-webkit-scrollbar {
    display: none;
  }