.items-container {
  position: inherit;
  margin: 43px 44px 12px 44px;
  padding: 20px;
  background-color: white;
  height: 650px;
  min-height: 650px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  max-width: 2000px;
}

.color-picker-background {
  background-color: white;
  padding: 4px;
  height: 34px;
  width: 34px;
  cursor: pointer;
}

.color-picker-background:hover {
  background-color: #f5f5f9;
  border-radius: 50%;
}

.color-picker-foreground {
  height: 26px;
  width: 26px;
  border-radius: 13px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 2px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 6px;
}

/* Firefox */
input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 6px;
}

.ui.bottom.center.popup:before {
  background-color: transparent !important;
}

.category-icons-items:hover {
  background-color: #eeecfc9d;
  border-radius: 6px;
}

.ui.dropdown.products-dropdown>.dropdown.icon {
  margin-left: 2px !important;
}

.item-modal-input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #dedede;
  background-color: #f5f5f9;
  padding: 10px;
  color: black;
  font-family: 'Montserrat';
}

.item-modal-input:disabled {
  background-color: #f5f5f945;
  border: 1px solid #dedede45;
  color: #00000075;
}

.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-wrapper .loader {
  position: absolute;
  top: 30%;
  right: 10px;
  transform: translateY(-50%);
  border: 2px solid #0000001a;
  border-radius: 50%;
  border-top: 2px solid #767676;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.item-modal-input:focus {
  outline: none !important;
  border: 1px solid #7268cd;
}

.item-modal-input-price {
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color: #f5f5f9;
  padding: 10px;
  padding-right: 0px;
  color: black;
  font-family: 'Montserrat';
}

.item-modal-input-price:focus {
  outline: none !important;
  border: nnone;
}

.item-modal-textarea:focus {
  outline: 1px solid #7268cd;
  border: 1px solid #7268cd;
}

.item-modal-button-add {
  min-width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #66c666;
  background-color: #f5f5f9;
  padding: 0px;
  color: green;
  font-size: 30px;
  font-weight: 600;
  font-family: 'Montserrat';
}

.item-modal-button-add:disabled {
  color: #cde2cd;
  background-color: #f5f5f9;
  border: 1px solid #dedede;
}

.item-modal-button-add:hover {
  background-color: #c3e2c3;
}

.item-modal-button-add:disabled:hover {
  background-color: #f5f5f9;
}

.item-modal-button-remove {
  min-width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #d38787;
  background-color: #f5f5f9;
  padding: 0px;
  color: red;
  font-size: 30px;
  font-weight: 600;
  font-family: 'Montserrat';
}

.item-modal-button-remove:hover {
  background-color: #deb5b5;
}

.items-modal-section-group {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.items-modal-group-section {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  padding: 12px;
}

.items-modal-section-title {
  font-size: 16px; 
  font-weight: 700;
  margin-bottom: 20px;
}

.item-modal-buttongroup-button {
  font-family: 'Montserrat' !important;
  font-size: 12px !important;
  padding-top: 7px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 27px !important;
}

.item-modal-buttongroup-button-big {
  font-family: 'Montserrat' !important;
  font-size: 12px !important;
  padding-top: 7px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: auto !important;
  min-height: 43px !important;
  max-width: 100% !important;
  display: block !important;
  text-align: center !important;
  line-height: 1.2 !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.items-table {
  border-radius: 0px !important;
  border-width: 0px !important;
}

.items-table-container {
  overflow-y: scroll;
  height: 545px;
  margin-top: 20px;
  scrollbar-width: none;
  border-radius: 20px;
  border-width: 1px;
  border-color: #eee;
  border-style: solid;
}

.items-table-container::-webkit-scrollbar {
  display: none;
}

.items-header-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.items-search {
  margin-right: auto;
  margin-left: 0;
}

.items-pagination {
  display: table;
  margin: 0 auto;
}

.items-dropdown {
  margin-left: auto;
  margin-right: 0;
}

.items-modal-dropdown {
  margin-left: 0;
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .items-search {
    display: table;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .items-pagination {
    width: 100%;
  }

  .items-pagination-center {
    display: table;
    margin: 0 auto;
  }
  
  .items-dropdown {
    display: table;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 14px;
  }

  .items-container {
    height: 740px;
  }

  .items-modal-section-group {
    flex-direction: column-reverse;
  }
}

.ui.secondary.pointing.menu .active.item {
  border-color: #7268cd !important;
}

.ui.secondary.pointing.menu {
  border-bottom: 2px solid rgba(34,36,38,.1) !important;
}

.ui.selection.active.dropdown:hover {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  0px 0px  !important;
}

.ui.selection.dropdown:focus {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  20px 20px  !important;
}

.ui.selection.active.dropdown {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px 20px 20px !important;
}

.ui.selection.active.dropdown .menu {
  border: 1px solid #7268cd !important;
  border-radius: 0px 0px 20px 20px !important;
  border-top: none !important;
}

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.ui.search .prompt {
  border-radius: 20px !important;
}

.ui.selection.dropdown {
  border-radius: 20px !important;
}

.ui.selection.dropdown .menu {
  border-radius: 0 0 20px 20px !important;
}

.items-modal-title {
  font-weight: 600;
}

.ui.button.items-btn, .ui.buttons.items-btn .button.items-btn, .ui.buttons.items-btn .or.items-btn {
  color: #7268cd !important;
  background-color: transparent !important;
  margin-right: -12px !important;
  font-weight: 700 !important;
  box-shadow: none !important;
}

.ui.button.items-close-btn, .ui.buttons.items-close-btn .button.items-close-btn, .ui.buttons.items-close-btn .or.items-close-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  border: none !important;
  margin-right: -8px !important;
  margin-top: -6px !important;
  font-weight: 200 !important;
  position: absolute !important;
  float: right !important;
  right: 15px !important;
}

.ui.button.items-btn:hover, .ui.buttons.items-btn:hover .button.items-btn:hover, .ui.buttons.items-btn:hover .or.items-btn:hover {
  color: #7268cd !important;
  background-color: transparent !important;
  margin-right: -12px !important;
  font-weight: 700 !important;
  box-shadow: none !important;
}

.items-modal-content {
  margin-left: 20px;
}

.items-modal-content > * {
  margin-bottom: 26px;
}

.items-modal-header {
  display: flex;
}

.items-modal-header > :first-child {
  margin-right: 50px;
  width: 230%;
}

.items-modal-textarea {
  min-height: 30px;
  max-height: 82px;
  max-width: 680px;
  border-color: #ddd;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  padding-left: 15px;
  font-family: 'Montserrat', sans-serif;
}

.items-modal-textarea::placeholder {
  color: #bbb;
}

.items-modal-textarea:focus {
  outline-color: none !important;
  border: 1px solid #7268cd !important;
  outline-width: 0 !important;
  outline: none !important;
}

.items-modal-textarea-barcode {
  min-height: 38px;
  max-height: 82px;
  max-width: 680px;
  border-color: #ddd;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  padding-left: 15px;
  font-family: 'Montserrat', sans-serif;
  resize: none;
}

.items-modal-textarea-barcode::placeholder {
  color: #bbb;
}

.items-modal-textarea-barcode:focus {
  outline-color: none !important;
  border: 1px solid #7268cd !important;
  outline-width: 0 !important;
  outline: none !important;
}

.item-image {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover !important;
}

.ui.form input[type=text]:focus {
  border-color: #7268cd !important;
}

.ui.selection.active.dropdown.items-dropdown:hover {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 5px 5px 0px 0px !important;
}

.ui.selection.dropdown.items-dropdown:focus {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 5px 5px 0px 0px !important;
}

.ui.selection.active.dropdown.items-dropdown {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 5px 5px 0px 0px !important;
}

.ui.selection.active.dropdown.items-dropdown .menu.items-dropdown {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 5px 5px 0px 0px !important;
}

.ui.selection.dropdown.items-dropdown {
  margin-top: -5px;
  border-radius: 5px 5px !important;
  border-color: #ddd;
  max-width: 681px !important;
}

.ui.selection.dropdown.items-dropdown .menu {
  border-radius: 0 0 5px 5px !important;
  max-width: 681px !important;
}

.ui.selection.active.dropdown.items-modal-dropdown:hover {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 5px 5px 0px 0px !important;
}

.ui.selection.dropdown.items-modal-dropdown:focus {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 5px 5px 0px 0px !important;
}

.ui.selection.active.dropdown.items-modal-dropdown {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 5px 5px 0px 0px !important;
}

.ui.selection.active.dropdown.items-modal-dropdown .menu.items-modal-dropdown {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 5px 5px 0px 0px !important;
}

.ui.selection.dropdown.items-modal-dropdown {
  margin-top: -5px;
  border-radius: 5px 5px !important;
  border-color: #ddd;
  max-width: 681px !important;
}

.ui.selection.dropdown.items-modal-dropdown .menu {
  border-radius: 0 0 5px 5px !important;
  max-width: 681px !important;
}

.ui.selection.active.dropdown.items-modal-dropdown-new:hover {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 10px 10px 0px 0px !important;
  min-height: 40px !important;
  background-color: #f5f5f9;
}

.ui.selection.dropdown.items-modal-dropdown-new:focus {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 10px 10px 0px 0px !important;
  min-height: 40px !important;
  background-color: #f5f5f9;
}

.ui.selection.active.dropdown.items-modal-dropdown-new {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 10px 10px 0px 0px !important;
  min-height: 40px !important;
  background-color: #f5f5f9;
}

.ui.selection.active.dropdown.items-modal-dropdown-new .menu.items-modal-dropdown-new {
  border-color: #7268cd !important;
  max-width: 681px !important;
  border-radius: 10px 10px 0px 0px !important;
  min-height: 40px !important;
  background-color: #f5f5f9;
}

.ui.selection.dropdown.items-modal-dropdown-new {
  margin-top: -5px;
  border-radius: 10px 10px !important;
  border-color: #ddd;
  max-width: 681px !important;
  min-height: 40px !important;
  background-color: #f5f5f9;
}

.ui.selection.dropdown.items-modal-dropdown-new .menu {
  border-radius: 0 0 10px 10px !important;
  max-width: 681px !important;
  min-height: 40px !important;
  background-color: #f5f5f9;
}

.ui.multiple.dropdown.items-modal-dropdown-new>.label {
  background-color: #e6e3fa !important;
  color: #7268cd !important;
}

.items-specialSelections-group {
  width: 102.5% !important;
}

.items-allergens-group {
  width: 102.5% !important;
}

.items-specialSelections-price {
  width: 105px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.items-specialSelections-price:focus {
  border: 1px solid #7268cd !important;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"].withBorder + label {
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #7268cd;
  color: #7268cd;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: 1rem;
  font-weight: 500;
  height: 36px;
  width: 130px;
  transition: background-color 0.3s;
}

.ui.icon.input {
  border: none !important;
  box-shadow: none !important;
}

.ui.icon.input.focus>input, .ui.icon.input>input:focus {
  border: none !important;
  box-shadow: none !important;
}

.ui.focus.search.ui.search.items-searchbar {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  20px 20px  !important;
  box-shadow: none !important;
}

.ui.image.items-modal-image img, .ui.image.items-modal-image svg { 
  width: 100% !important;
  object-fit: cover !important;
  aspect-ratio: 1/1 !important;
  display: table !important;
  margin: 0 auto !important;
}

@media screen and (max-height: 610px) {
  .items-container {
    margin-bottom: 240px;
  }
}

@media screen and (max-width: 767px) {
  .items-container {
    height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
    margin: 43px 16px 12px 16px;
  }

  .items-table-container {
    height:  calc(100vh - 460px);
  }

  .items-specialSelections-group {
    flex-wrap: nowrap !important;
  }

  .items-allergens-group {
    flex-wrap: nowrap !important;
  }

  .items-modal-header > :first-child {
    margin-right: 20px;
    width: 180%;
  }
}

@media screen and (max-height: 830px) and (min-width: 768px){
  .items-container {
    height: calc(100vh - 230px);
    min-height: calc(100vh - 230px);
  }

  .items-table-container {
    height:  calc(100vh - 315px);
  }
}

.ui.modal {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.ui.modal {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}