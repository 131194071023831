.checkout-screen-container {
  position: absolute;
  width: 100vw;
  z-index: 1081;
  top: 90px;
}

.checkout-screen-image {
  height: 200px;
  width: 100vw;
  object-fit: cover;
  border-radius: 40px 40px 0px 0px;
  background-color: transparent;
}

.checkout-screen-scroll-container {
  z-index: 1054;
  width: 100vw;
  height: calc(100vh + 35px);
  position: absolute;
  background-color: white;
  border-radius: 40px 40px 0px 0px;
  margin-top: -40px;
  padding-top: 0px;
}

.checkout-screen-scroll-container-inside {
  z-index: 1052;
  width: 100vw;
  height: calc(100vh);
  padding: 22px;
  padding-top: 0px;
  padding-bottom: 260px;
  background-color: white;
  overflow-y: auto;
  bottom: 0;
}

.checkout-screen-total-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 12px;
}

.checkout-screen-scroll-container-inside::-webkit-scrollbar {
  display: none;
}

.checkout-screen-header {
  display: flex;
  width: 100vw;
  justify-content: center;
  height: 30px;
  padding-top: 14px;
  padding-bottom: 60px;
  border-radius: 40px 40px 0px 0px;
}

.checkout-screen-bookedItem-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 24px;
}

.checkout-screen-specialSelections-count-flex-qr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: 20px;
  font-size: 13px;
  color: #444;
  font-style: italic;
  gap: 5px;
}

.checkout-screen-specialSelections-count-flex-terminal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: 23px;
  font-size: 13px;
  color: #444;
  font-style: italic;
  gap: 5px;
}

.checkout-screen-specialSelections {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.checkout-screen-tile-container {
  height: auto;
  background-color: white;
  padding: 20px;
  min-height: 146px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 3px 0px;
  margin-bottom: 24px;
}

.checkout-screen-tile-title {
  font-size: 17px;
  font-weight: 600;
}

.checkout-screen-title {
  font-size: 20px;
  font-weight: 600;
  padding-left: 0px;
}

.checkout-screen-price {
  font-size: 22px;
  font-weight: 600;
  color: #555;
}

.checkout-screen-category {
  color: #888;
}

.checkout-screen-description {
  font-weight: 500;
  color: #444;
}

.checkout-screen-subtitles {
  font-weight: 700;
  font-size: 16px;
}

.checkout-screen-special-selection-container {
  display: flex;
  justify-content: space-between;
}

.checkout-screen-textlist {
  font-weight: 500;
  color: #444;
}

.checkout-screen-textarea {
  min-height: 30px;
  max-height: 82px;
  border-color: #ddd;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  padding-left: 15px;
  font-family: 'Montserrat', sans-serif;
}

.checkout-screen-textarea::placeholder {
  color: #bbb;
}

.checkout-screen-textarea:focus {
  outline-color: none !important;
  border: 1px solid #aaa !important;
  outline-width: 0 !important;
  outline: none !important;
}

.customer-checkout-payment-button {
  bottom: 30px;
  display: table;
  margin: auto 0;
  width: 100%;
  height: 45px;
  border-radius: 20px;
  padding-top: 12px;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  color: white;
  cursor: 'pointer';
}

.checkin-cash-modal {
  height: 0px;
  top: 0%;
  -webkit-transition: 2.4s;
  transition: 2.4s;
  position: fixed;
  z-index: 1095;
  -webkit-transition: height 2.4s;
  transition: height 2.4s;
}

.checkin-cash-modal.show-cash {
  top: initial;
  bottom: 0;
  height: 100%;
  -webkit-transition: height 2.4s;
  transition: height 2.4s;
}

.checkin-cash-modal-overlay {
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 1094;
}

.checkin-cash-modal-overlay.show-cash {
  display: block;
}

.checkin-cash-container {
  position: absolute;
  width: 100vw;
  min-height: 200px;
  border-radius: 20px 20px 0px 0px;
  background-color: white;
  padding: 20px;
  bottom: 0;
  z-index: 1099;
}

.checkin-card-modal {
  height: 0px;
  top: 0%;
  -webkit-transition: 2.4s;
  transition: 2.4s;
  position: fixed;
  z-index: 1095;
  -webkit-transition: height 2.4s;
  transition: height 2.4s;
}

.checkin-card-modal.show-card {
  top: initial;
  bottom: 0;
  height: 100%;
  -webkit-transition: height 2.4s;
  transition: height 2.4s;
}

.checkin-card-modal-overlay {
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 1094;
}

.checkin-card-modal-overlay.show-card {
  display: block;
}

.checkin-card-container {
  position: absolute;
  width: 100vw;
  min-height: 200px;
  border-radius: 20px 20px 0px 0px;
  background-color: white;
  padding: 20px;
  bottom: 0;
  z-index: 1099;
}

.email-checkout-input {
  width: 100%;
  height: 45px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.ui.input.email-checkout-input>input:focus {
  border: 1px solid gray !important;
}

.confirm-zindex {
  z-index: 2500 !important; 
}

@media screen and (max-width: 320px) {
  .checkout-screen-header {
    height: 22px;
  }

  .checkout-screen-title {
    font-size: 22px;
  }
  
  .checkout-screen-price {
    font-size: 17px;
  }
  
  .checkout-screen-category {
    color: #888;
    font-size: 12px;
  }
}
