.dashboard-container {
  margin: 5px 24px 44px 44px;
  animation: fadeInFromDownBookings1 0.3s ease-out !important;
}

.dashboard-toggle {
  padding-right: 4px;
}

@media screen and (max-width: 960px) {
  .dashboard-container {
    margin: 5px 25px 12px 24px;
  }

  .dashboard-toggle {
    padding-right: 4px;
  }

}

@media screen and (max-width: 768px) {
  .dashboard-container {
    margin: 5px 8px 44px 8px;
    padding-bottom: 100px;
  }

  .dashboard-toggle {
    padding-right: 18px;
    margin-top: 24px;
  }
}