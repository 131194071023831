.kpi-tile-container {
  position: relative;
  background-color: white;
  height: 175px;
  border-radius: 14px;
  box-shadow: rgb(114 104 205 / 20%) 0px 1px 5px;
  transition-property: box-shadow, transform;
  color: #333;
}

.kpi-tile-content-container {
  padding: 20px;
}

.kpi-tile-header {
  font-size: 18px;
  font-weight: 600;
  width: 77%;
}

.kpi-tile-title {
}

.kpi-tile-image {
  height: 25px;
  width: 25px;
}

.kpi-tile-image-container {
  position: absolute;
  right: 20px;
  top: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-left: 20px;
}

.kpi-tile-value {
  position: absolute;
  bottom: 10px;
  font-size: 39px;
  font-weight: 600;
}

.kpi-tile-footer {
  position: absolute;
  bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  width: 80%;
  color: #666;
}

.growth-positive {
  color: #2ECC71;
  font-weight: 700;
}

.growth-negative {
  color: #FF4136;
  font-weight: 700;
}

.growth-neutral {
  color: #aaa;
  font-weight: 700;
}

.currency {
  font-size: 15px;
}

@media screen and (max-width: 1058px) and (min-width: 991px) {
  .kpi-tile-header {
    font-size: 16px;
  }
  .kpi-tile-footer {
    font-size: 13px;
  }
}