.categories-tile {
  position: inherit;
  margin: 43px 44px 12px 44px;
  padding: 20px;
  background-color: white;
  height: calc(100vh - 210px);
  min-height: calc(100vh - 210px);
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}