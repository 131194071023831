.horizontal-bar-chart-container {
  position: relative;
  background-color: white;
  height: 520px;
  border-radius: 14px;
  box-shadow: rgb(114 104 205 / 20%) 0px 1px 5px;
  transition-property: box-shadow, transform;
  color: #333;
  padding: 20px;
}

.horizontal-bar-content-container {
}

.horizontal-bar-chart-header {
  font-size: 19px;
  font-weight: 600;
}

.horizontal-bar-chart-title {
  margin-right: 20px;
}

.horizontal-bar-chart-image-container {
  position: absolute;
  right: 20px;
  top: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-left: 20px;
}

.horizontal-bar-chart-image {
  height: 25px;
  width: 25px;
}

.horizontal-bar-chart-legend {
  
}

.horizontal-bar-chart-legend-title {
  margin-right: 30px;
  color: #666;
  font-weight: 700;
  font-size: 14px;
}

.horizontal-bar-chart-legend-value {
  float: left;
  margin-right: 30px;
  color: #666;
  margin-top: -16px;
  font-weight: 500;
  font-size: 32px;
  color: rgba(99, 164, 255, 0.9);
  margin-bottom: 30px;
}

.horizontal-bar-chart-bar {
  margin-top: 60px;
}
