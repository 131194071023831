.customer-item-screen-container {
  position: absolute;
  width: 100vw;
  z-index: 1081;
  top: 50px;
}

.customer-item-screen-image {
  height: 200px;
  width: 100vw;
  object-fit: cover;
  border-radius: 40px 40px 0px 0px;
  background-color: white;
}

.customer-item-screen-scroll-container {
  z-index: 1054;
  width: 100vw;
  height: calc(100vh - 200px + 35px);
  position: absolute;
  background-color: white;
  border-radius: 40px 40px 0px 0px;
  margin-top: -40px;
  padding-top: 28px;
}

.customer-item-screen-scroll-container-inside {
  position: absolute;
  border-radius: 40px 40px 0px 0px;
  margin-top: -400px;
  z-index: 1052;
  width: 100vw;
  height: calc(100vh - 165px);
  padding: 28px;
  padding-bottom: 260px;
  background-color: white;
  overflow-y: auto;
  bottom: 0;
}

.customer-item-screen-scroll-container-inside::-webkit-scrollbar {
  display: none;
}

.customer-item-screen-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 30px;
}

.customer-item-screen-title {
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  width: 74%;
  word-break: break-all;
}

.customer-item-screen-price {
  font-size: 19px;
  font-weight: 600;
  color: #555;
  text-align: right;
}

.customer-item-screen-category {
  color: #888;
}

.customer-items-checkout-button {
  position: fixed;
  z-index: 1084;
  bottom: 30px;
  width: calc(100vw - 70px);
  height: 55px;
  border-radius: 20px;
  padding-top: 17px;
  text-align: center;
  margin-left: 35px;
  font-size: 21px;
  font-weight: 600;
  color: white;
}

.customer-item-screen-description {
  font-weight: 500;
  color: #444;
  word-break: break-all;
}

.customer-item-screen-subtitles {
  font-weight: 700;
  font-size: 16px;
  margin-top: 6px;
}

.customer-item-screen-special-selection-container {
  display: flex;
  justify-content: space-between;
}

.customer-item-screen-textlist {
  font-weight: 500;
  color: #444;
  word-break: break-all;
}

.customer-item-screen-textarea {
  min-height: 82px;
  max-height: 82px;
  border-color: #ddd;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 15px;
  padding: 10px;
  padding-left: 15px;
  font-family: 'Montserrat', sans-serif;
  resize: none;
}

.customer-item-screen-textarea::placeholder {
  color: #bbb;
}

.customer-item-screen-textarea:focus {
  outline-color: none !important;
  border: 1px solid #aaa !important;
  outline-width: 0 !important;
  outline: none !important;
}

@media screen and (max-width: 320px) {
  .customer-item-screen-header {
    min-height: 20px;
  }

  .customer-item-screen-title {
    font-size: 17px;
    width: 70%;
  }
  
  .customer-item-screen-price {
    font-size: 17px;
  }
  
  .customer-item-screen-category {
    color: #888;
    font-size: 12px;
  }
}
