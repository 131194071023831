.pin-input-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    gap: 20px;
}

.pin-input {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: white;
    height: 55px;
    width: 55px;
    border: 1px solid #7268cd;
    border-radius: 6px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none; 
        -ms-user-select: none;
            user-select: none; 
}

.pin-numpad-container {
    padding-top: 30px;
    margin-bottom: 32px;
}

.pin-numpad-line {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    gap: 20px;
    margin-bottom: 12px;
}

.pin-numpad-num {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: white;
    height: 56px;
    width: 56px;
    border: 1px solid #7268cd;
    border-radius: 28px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none; 
        -ms-user-select: none;
            user-select: none; 
}

.pin-delete-button {
    width: 100%;
    text-align: center;
    border: 1px solid red;
    border-radius: 10px;
    height: 40px;
    flex-direction: column;
    color: red;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.pin-delete-button-disabled {
    width: 100%;
    text-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    height: 40px;
    flex-direction: column;
    color: #e6e6e6;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none; 
        -ms-user-select: none;
            user-select: none; 
}

.pin-send-button {
    width: 100%;
    text-align: center;
    border: 1px solid #7268cd;
    border-radius: 10px;
    height: 40px;
    flex-direction: column;
    color: white;
    background-color: #7268cd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.pin-send-button-disabled {
    width: 100%;
    text-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    height: 40px;
    flex-direction: column;
    color: white;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    font-weight: 600;
    font-size: 16px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none; 
        -ms-user-select: none;
            user-select: none; 
}
