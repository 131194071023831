.add-hardware-container {
  position: inherit;
  margin: 43px 14px 12px 44px;
  padding: 20px;
  background-color: white;
  min-height: 300px;
  max-height: calc(100vh - 240px);
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  scrollbar-width: none;
}

.add-hardware-container::-webkit-scrollbar {
  display: none;
}