.customer-home-nav {
  display: flex;
  max-width: 100vw;
  height: 64px;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.customer-home-nav-title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  flex: 1 0 auto;
  word-break: break-all;
  width: 100%;
}

.customer-home-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1031;
  background-color: #fff;
}

.customer-item-list-closed {
  bottom: 0;
  background: rgb(255,255,255);
  top: 55px;
  position: fixed;
  padding: 50px;
  width: 100vw;
}

.customer-item-list {
  bottom: 0;
  background: rgb(255,255,255);
  top: 218px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 110px;
  padding-top: 10px;
  max-height: 100%;
  width: 100vw;
}

.customer-item-list::-webkit-scrollbar {
  display: none;
}

.customer-item-scroll-list::-webkit-scrollbar {
  display: none;
}

.customer-category-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 112px;
  cursor: 'pointer';
}

.customer-category-image {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  margin: 5px 15px 10px 15px;
  border-radius: 15px;
  background-color: #dfe0e3;
  display: table;
}

.customer-category-wrapper::-webkit-scrollbar {
  display: none;
}

.customer-category-text {
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -5px;
  text-align: center;
  width: 100px;
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: unset;
  overflow: hidden;
  font-weight: 600;
  word-break: break-word;
}

.customer-title {
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
  padding-left: 18px;
  height: 46px;
  margin-bottom: 0px;
  z-index: 1010;
  background-color: transparent;
}

.customer-title-shadow {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
  padding-left: 18px;
  height: 46px;
  margin-bottom: 0px;
  z-index: 1010;
  background-color: transparent;
}

.customer-item-container {
  background-color: white;
  border-radius: 20px;
  height: 90px;
  cursor: pointer;
}

.customer-item-image {
  height: 65px;
  width: 65px;
  margin: 12px;
  border-radius: 12px;
  object-fit: cover !important;
  aspect-ratio: 1/1 !important;
  color: white;
}

.customer-item-texts {
  position: absolute;
  top: 0;
  left: 105px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 110px;
  margin: auto 0;
  width: calc(100vw - 89px - 80px - 28px - 15px);
}

.customer-item-title {
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 3px;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  
}

.customer-item-description {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 6px;
  color: #777;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.customer-item-price {
  position: absolute;
  font-weight: 600;
  font-size: 20px;
  text-align: right;
  right: 26px;
  top: 41px;
  width: 100px;
}

.customer-checkout-button {
  position: absolute;
  z-index: 1004;
  bottom: 30px;
  width: calc(100vw - 80px);
  height: 55px;
  border-radius: 20px;
  padding-top: 17px;
  text-align: center;
  margin-left: 40px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  cursor: 'pointer';
}

@media only screen and (max-width: 767px){ 
  .ui.grid>.stackable.stackable.row>.column.customer-home-grid-column, .ui.stackable.grid>.column.grid>.column.customer-home-grid-column, .ui.stackable.grid>.column.row>.column.customer-home-grid-column, .ui.stackable.grid>.column:not(.row).customer-home-grid-column, .ui.stackable.grid>.row>.column.customer-home-grid-column, .ui.stackable.grid>.row>.wide.column.customer-home-grid-column, .ui.stackable.grid>.wide.column.customer-home-grid-column {
    padding: 0.75rem 1rem!important;
  }
}

.customer-item-screen-modal {
  height: 0px;
  top: 0%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: fixed;
  z-index: 1072;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
}

.customer-item-screen-modal.show-item {
  top: initial;
  bottom: 0;
  height: 100%;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
}

.customer-item-screen-overlay {
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 1071;
}

.customer-item-screen-overlay.show-item {
  display: block;
}

.customer-checkout-screen-modal {
  height: 0px;
  top: 0%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: fixed;
  z-index: 1072;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
}

.customer-checkout-screen-modal.show-checkout {
  top: initial;
  bottom: 0;
  height: 100%;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
}

.customer-checkout-screen-overlay {
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 1071;
}

.customer-checkout-screen-overlay.show-checkout {
  display: block;
}

.corona-checkin-modal {
  height: 0px;
  top: 0%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: fixed;
  z-index: 1072;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
}

.corona-checkin-modal.show-corona {
  top: initial;
  bottom: 0;
  height: 100%;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
}

.corona-checkin-modal-overlay {
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 1071;
}

.corona-checkin-modal-overlay.show-corona {
  display: block;
}

.customer-stripe-overlay {
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 1071;
}

.customer-stripe-overlay.show-stripe-overlay {
  display: block;
}

.customer-stripe-modal {
  height: 0px;
  top: 0%;
  position: fixed;
  z-index: 1095;
}

.customer-stripe-modal.show-stripe-modal {
  top: initial;
  bottom: 0;
  height: 100%;
}

.opening-hours-keys::first-letter{
  text-transform: capitalize;
}

@media screen and (max-width: 320px) {
  .customer-item-description {
    display: none;
    line-height: 0px;
  }

  .customer-item-title {
    white-space: unset;
    font-size: 16px;
    margin-top: -50px;
  }

  .customer-item-price {
    top: 44px;
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .customer-item-container {
    margin-left: 14px;
    margin-right: 14px;
  }
  .customer-item-price {
    float: right;
    right: 6px;
    position: relative;
    top: 30px;
  }
  .customer-item-texts {
    margin-left: 14px;
    width: calc(100vw - 89px - 80px - 28px - 15px);
  }
}