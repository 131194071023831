.timeline-container::-webkit-scrollbar {
    display: none;
}

.timeline-container {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    gap: 14px;
    align-items: center;
    padding: 20px 0;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.timeline-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    text-align: center;
    flex-shrink: 0;
    width: 140px;
  }

.timeline-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.timeline-title {
    height: 40px;
    font-size: 16px;
    font-weight: 600;
  }

.timeline-popup-title {
    font-weight: 700;
}

  .timeline-title p {
    margin-bottom: 0;
  }

  .timeline-icon {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    border: 2px solid #7268cd;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .timeline-icon img {
    height: 19px;
    width: 19px;
  }

  .timeline-date {
    height: 40px;
  }

  .timeline-date p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
  }

  .timeline-date-subtitle {
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .timeline-connector {
    width: 60px;
    border: 1px solid #7268cd;
    height: 2px;
    margin-left: -45px;
    margin-right: -45px;
    flex-shrink: 0;
  }