.corona-checkin-container {
  width: calc(100vw - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  height: calc(100vh - 200px);
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  padding-top: 12px;
  overflow-y: scroll;
}

.corona-checkin-container::-webkit-scrollbar {
  display: none;
}