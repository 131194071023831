.vertical-bar-chart-container {
  position: relative;
  background-color: white;
  height: 450px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  padding: 20px;
}

.vertical-bar-content-container {
}

.vertical-bar-chart-header {
  font-size: 19px;
  font-weight: 600;
}

.vertical-bar-chart-title {
  margin-right: 4px;
}

.vertical-bar-chart-image-container {
  position: absolute;
  right: 20px;
  top: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-left: 20px;
}

.vertical-bar-chart-image {
  height: 25px;
  width: 25px;
}

.vertical-bar-chart-legend {
  
}

.vertical-bar-chart-legend-title {
  margin-right: 30px;
  color: #666;
  font-weight: 700;
  font-size: 14px;
}

.vertical-bar-chart-legend-value {
  float: left;
  margin-right: 30px;
  color: #666;
  margin-top: -16px;
  font-weight: 500;
  font-size: 32px;
  color: #a291d9;
  margin-bottom: 30px;
  text-overflow: ellipsis !important;
  width: 100% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vertical-bar-chart-bar {
  margin-top: 55px;
  margin-left: -8px;
}
