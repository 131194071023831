.accordion-sub-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.accordion-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
}

.accordion-key {
    font-weight: 600;
    margin-bottom: 0px;
}