@keyframes fadeInLogin {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLogin2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-login-text {
  animation: fadeInLogin 0.4s ease-out !important;
}

.animate-login-text2 {
  animation: fadeInLogin 0.55s ease-out !important;
}

.animate-login-img {
  animation: fadeInLogin2 0.55s ease-out !important;
}

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 40px;
  height: 300px;
  width: 350px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  color: #333;
  font-size: 1.52rem;
  font-weight: 400;
}

.login-link {
color: #7268cd;
}

.ui.left.icon.input.login-input:focus {
  box-shadow: none !important;
  border: 1px #7268cd solid !important;
  border-radius: 5px !important;
}

.ui.form .login-input input[type=text]:focus {
  border: 1px solid #7268cd !important;
}

.ui.form .login-input input[type=password]:focus {
  border: 1px solid #7268cd !important;
}

.login-button {
  box-shadow: none !important;
  border: 1px #7268cd solid !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600px !important;
  background-color: #7268cd !important;
  height: 38px !important;
  padding-top: 8px !important;
}

.ui.basic.fluid.button.login-button {
  box-shadow: none !important;
  border: 1px #7268cd solid !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600px !important;
  background-color: #7268cd !important;
  height: 38px !important;
  padding-top: 8px !important;
}

.ui.basic.fluid.button.login-button:hover {
  box-shadow: none !important;
  border: 1px #7268cd solid !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600px !important;
  background-color: #7268cdce !important;
  height: 38px !important;
  padding-top: 8px !important;
}