@keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.9) translateY(-100px);
    }
    50% {
      opacity: 1;
      transform: scale(1.03) translateY(10px);
    }
    70% {
      transform: scale(0.97) translateY(-5px);
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }
  
  @keyframes bounceOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05) translateY(-10px);
    }
    100% {
      opacity: 0;
      transform: scale(0.9) translateY(100px);
    }
  }
  
  .modal-bounce-in {
    animation: bounceIn 0.3s ease-out;
  }
  
  .modal-bounce-out {
    animation: bounceOut 0.3s ease-out;
  }