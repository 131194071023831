@keyframes fadeInFromDown {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOutToDown {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  @keyframes fadeIn {
    from {  
        opacity:0;  
    }  
 
    to {  
        opacity:1;  
    }  
  }
  
  .modal-fade-in {
    animation: fadeInFromDown 0.25s ease-out !important;
    transition: none;
  }
  
  .modal-fade-out {
    animation: fadeOutToDown 0.30s ease-out !important;
    transition: none;
  }

  .ui.dimmer {
    background-color: red !important;
    backdrop-filter: blur(0px);
  }
  
  .ui.dimmer.visible {
    opacity: 1;
    visibility: visible;
    background-color: #312d512c !important;
    animation: fadeIn 0.3s ease !important;
    backdrop-filter: blur(5px);
  }

  .ui.modal {
    box-shadow: 1px 3px 3px 0 #7268cd35, 1px 3px 15px 2px #7268cd35 !important;
  }