* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  overscroll-behavior: none;
}

html, body {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

p {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

div {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

p.cockpit-title {
  color: #333;
  font-size: 3.5rem;
  font-weight: 700;
}

p.cockpit-subtitle {
  color: #999;
  font-size: 1.2rem;
  font-weight: 400;
}

p.cockpit-subtitle-employee {
  display: none;
}

@keyframes titleFade {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cockpit-title {
  margin-left: 22px;
  margin-top: 6px;
  margin-bottom: -8px;
  animation: titleFade 0.1s ease-out !important;
}

.cockpit-subtitle {
  margin-left: 26px;
  animation: titleFade 0.3s ease-out !important;
}

.container-loggedin {
  display: flex;
  height: calc(100vh - 64px);
  background-color: #f5f5f9;
  min-height: 500px;
  width: 100vw;
}

.container-loggedout {
  height: 100vh;
  background-color: #f5f5f9;
  min-height: 500px;
  width: 100vw;
}

.main {
  flex: 1;
  margin-left: 44px;
  overflow: auto;
  scrollbar-width: none;
}

.main-franchisor {
  flex: 1;
  margin-left: 44px;
  overflow: hidden;
  scrollbar-width: none;
}

.main-employee {
  flex: 1;
  overflow: hidden;
  scrollbar-width: none;
}

.main::-webkit-scrollbar {
  display: none;
}

.main-franchisor::-webkit-scrollbar {
  display: none;
}

.main-employee::-webkit-scrollbar {
  display: none;
}

.ui.modal .actions>.button {
  border-radius: 8px;
}

.ui.fixed.table th {
  overflow: hidden;
  text-overflow: none;
}

.ui.celled.table tr td, .ui.celled.table tr th {
  border-left: none !important;
}

.ui.selectable.table tbody tr:hover,
.ui.table tbody tr td.selectable:hover {
  background: #eeecfc7d !important;
  color: rgba(0, 0, 0, .95) !important;
}

#root .ui.selectable.table tbody tr:hover,
#root .ui.table tbody tr td.selectable:hover {
  background: #eeecfc7d !important;
}

.div-hover:hover {
  background-color: #eeecfc7d !important;
}

@media screen and (max-width: 960px) {
  .cockpit-title {
    margin-left: 10px;
  }

  .cockpit-subtitle {
    margin-left: 12px;
  }

  .main {
    margin-left: 0px;
  }

  .main-franchisor {
    margin-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  .not-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 481px) {
  .not-mobile {
    display: unset;
  }
}

@media screen and (max-height: 700px) {
  .cockpit-subtitle {
    display:none;
  }
}

@media screen and (max-width: 767px) {
  .cockpit-subtitle {
    display:none;
  }

  .cockpit-title {
    margin-left: 10px;
    margin-bottom: -25px;
    margin-top: 0px;
  }

  p.cockpit-title {
    color: #333;
    font-size: 26px;
    font-weight: 700;
  }
}